// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

div:first-child > img.gallery-preview-img {
  max-height: 205px !important;
}

.ql-editor {
  height: calc(100vh - 350px) !important; /* Adjust the minimum height as needed */
  font-family: Montserrat;
}

/* Set dropdown font-families */

.ql-picker.ql-font .ql-picker-label[data-value='Montserrat']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Montserrat']::before {
  font-family: 'Montserrat', cursive;
  content: 'Montserrat' !important;
}
.ql-picker.ql-font .ql-picker-label[data-value='Playfair']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Playfair']::before {
  font-family: 'Playfair', cursive;
  content: 'Playfair' !important;
  font-size: 16px;
}
/* Set content font-families */
.ql-font-Montserrat {
  font-family: 'Montserrat';
}
.ql-font-Playfair {
  font-family: 'Playfair';
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='9px']::before {
  content: '9' !important;
  font-size: 9px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='10px']::before {
  content: '10' !important;
  font-size: 10px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='11px']::before {
  content: '11' !important;
  font-size: 11px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='12px']::before {
  content: '12' !important;
  font-size: 12px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='14px']::before {
  content: '14' !important;
  font-size: 14px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='16px']::before {
  content: '16' !important;
  font-size: 16px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='18px']::before {
  content: '18' !important;
  font-size: 18px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='20px']::before {
  content: '20' !important;
  font-size: 20px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='22px']::before {
  content: '22' !important;
  font-size: 22px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='24px']::before {
  content: '24' !important;
  font-size: 24px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='26px']::before {
  content: '26' !important;
  font-size: 26px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='28px']::before {
  content: '28' !important;
  font-size: 28px !important;
}

.ql-size-9px {
  font-size: 9px;
}
.ql-size-10px {
  font-size: 10px;
}
.ql-size-11px {
  font-size: 11px;
}
.ql-size-12px {
  font-size: 12px;
}
.ql-size-14px {
  font-size: 14px;
}
.ql-size-16px {
  font-size: 16px;
}
.ql-size-18px {
  font-size: 18px;
}
.ql-size-20px {
  font-size: 20px;
}
.ql-size-22px {
  font-size: 22px;
}
.ql-size-24px {
  font-size: 24px;
}
.ql-size-26px {
  font-size: 26px;
}
.ql-size-28px {
  font-size: 28px;
}

.bold-text {
  font-weight: 600;
}
.terms-table {
  border: 1px solid black;
  border-collapse: collapse;
}
.terms-table > tr > td {
  border: 1px solid black;
  padding: 8px;
  text-align: start;
}
